
import { CLD_ENV, NODE_ENV } from "../../constants/env";
import { useURLs } from "../../constants/api.urls";
import nuxtStorage from "nuxt-storage";
import { useUnAutorize } from "~/composables/unAuthHandler";

export const getUser = async () => {
  const token = nuxtStorage.localStorage.getData("token");
  if (!token) { return;  }
    const url = useURLs[NODE_ENV].USER;
    const { data } = await useFetch(url, {
      key: `user`,
      method: "GET",
      headers: {
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
        "Content-Type": "application/json",
        Authorization: `Bearer  ${token}`,
      },
      retry: 1,
    });
    return data;
 
};
export const saveUser = async (payload) => {
  const token = nuxtStorage.localStorage.getData("token");
  if (token) {
    const url = useURLs[NODE_ENV].USER;
    const { data } = await useFetch(url, {
      key: `${payload}`,
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
        "Content-Type": "application/json",
        Authorization: `Bearer  ${token}`,
      },
      retry: 1,
    });
    return data;
  }
  return false;
};
export const sendContactEmail = async ({ email, message, phone, name }) => {
  const url = useURLs[CLD_ENV].CLD_SEND_CONTACT_EMAIL;
  const data = await useFetch(url, {
    key: email,
    method: "POST",
    body: JSON.stringify({ message, phone, name, email }),
    headers: {
      "Content-Type": "application/json",
    },
    retry: 1,
  });
  return data;
};
export const sendContactEmailServer = async ({
  email,
  message,
  phone,
  name,
}) => {
  const url = useURLs[CLD_ENV].CLD_SEND_CONTACT_EMAIL;
  const data = await $fetch(url, {
    key: email,
    method: "POST",
    body: JSON.stringify({ message, phone, name, email }),
    headers: {
      "Content-Type": "application/json",
    },
    retry: 1,
  });
  console.log("from server http call to email contact", data);
  return data;
};

export const updateUser = async (payload) => {
  const token = nuxtStorage.localStorage.getData("token");
  if (token) {
    const url = useURLs[NODE_ENV].USER;
    const { data } = await useFetch(url, {
      key: `${payload}`,
      method: "PATCH",
      body: payload,
      headers: {
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
        "Content-Type": "application/json",
        Authorization: `Bearer  ${token}`,
      },
      retry: 1,
    });
    return data;
  }
  return false;
};

export const getCldUser = async () => {
  try {
    const token = nuxtStorage.localStorage.getData("token");

    if (token) {
      const url = useURLs[CLD_ENV].CLD_USER;
      const { data } = await useFetch(url, {
        key: `CLD_USER`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer  ${token}`,
        },
        onResponseError({ response }) {
          if (response.status === 403) {
            useUnAutorize();
          }
        },
        retry: 1,
      });

      return data;
    }
  } catch (error) {
    console.log("error", error);
  }
  return false;
};

export const updateUserEmails = async (payload) => {
  try {
    const token = nuxtStorage.localStorage.getData("token");
    if (token) {
      const url = useURLs[CLD_ENV].CLD_UPDATE_EMAILS;
      const { data } = await useFetch(url, {
        key: `CLD_UPDATE_EMAILS`,
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer  ${token}`,
        },
        onResponseError({ response }) {
          if (response.status === 403) {
            useUnAutorize();
          }
        },
        retry: 1,
      });
      return data;
    }
  } catch (error) {
    console.log("error", error);
  }
  return false;
};


export const getIsPurchaseOrderRequired = async () => {
  try {
    const token = nuxtStorage.localStorage.getData("token");

    if (token) {
      const url = useURLs[CLD_ENV].IS_PO_Required;
      const { data } = await useFetch(url, {
        key: `IS_PO_Required`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer  ${token}`,
        },
        onResponseError({ response }) {
          if (response.status === 403) {
            useUnAutorize();
          }
        },
        retry: 1,
      });

      return data;
    }
  } catch (error) {
    console.log("error getting PO", error);
  }
  return false;
};