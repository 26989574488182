import { defineStore } from "pinia";
import { ref } from "vue";
import { CLD_ENV, NODE_ENV } from "~/constants/env";
import nuxtStorage from "nuxt-storage";
import { useURLs } from "~/constants/api.urls";
import { useI18n } from "vue-i18n";
import { useUnAutorize } from "~/composables/unAuthHandler";
import axios from "axios";

export const useUtilityStore = defineStore("utility", () => {
  const selectedLocale = ref("en");
  const newsLetter = ref();
  const { locale, setLocale } = useI18n();

  const languageMap = ref({
    fr: "fr_BE",
    en: "en_GB",
    de: "de_DE",
    nl: "nl_BE",
    es: "es_ES",
    sv: "sv_SE",
  });
  const selectedLanguage = ref(languageMap.value["en"]); //ref("en");
  // const pimSelectedLanguage = computed(()=>languageMap.value[selectedLanguage.value]);

  // if (process.client) {
  //   setTimeout(() => {
  //     selectedLanguage.value =
  //       nuxtStorage.localStorage.getData("selectedLanguage") || "en_GB";
  //     selectedLocale.value =
  //       nuxtStorage.localStorage.getData("selectedLocale") || "en-GB";
  //     locale.value = selectedLanguage.value.split("_")[0];
  //   }, 0);
  // }
  const setLanguage = (language) => {
    selectedLanguage.value = languageMap.value[language];
    selectedLocale.value = language;
    localStorage?.setItem("selectedLanguage", language);
    nuxtStorage.localStorage.setData("selectedLanguage", language, 100, "y");
    nuxtStorage.localStorage.setData(
      "selectedLocale",
      selectedLocale.value,
      100,
      "y"
    );

    setLocale(language);
    // locale.value = selectedLanguage.value.split("_")[0];
  };
  const downloadPdfDoc = async ({ id, journal, piece }) => {
    const url = `${useURLs[CLD_ENV].DOWNLOAD_UTIL_DOC}?id=${id}&journal=${journal}&piece=${piece}`;
    const token = nuxtStorage.localStorage.getData("token");
    if (!token) {
      return;
    }
    const { data } = await useFetch(url, {
      method: "GET",
      headers: {
        Accept: "application/pdf",
        Authorization: `Bearer  ${token}`,
      },
      onResponseError({ response }) {
        if (response.status === 403) {
          useUnAutorize();
        }
      },
    });
    const downloadUrl = window.URL.createObjectURL(data.value);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", `${journal}_${piece}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };
  // news letter registration
  const createContactCrm = async ({ firstName, lastName, email }) => {
    const url = useURLs[NODE_ENV].CRM_CREATE_CONTACT;
    const payload = JSON.stringify({ firstName, lastName, email });
    const { data } = await useFetch(url, {
      key: `${payload}`,
      method: "POST",
      body: payload,
      headers: {
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
        "Content-Type": "application/json",
      },
      retry: 2,
    });

    if (data.value && data.value.contact) {
      newsLetter.value = data.value.contact;
    } else {
      newsLetter.value = false;
    }
  };
  const downloadImages = async (images, id) => {
    try {
      const imagesPath = images.map(
        (image) => `https://assets.cld.be/cdn-cgi/image/quality=100/${image}`
      );
      const url = useURLs[NODE_ENV].IMAGE_FILE;
      const response = await axios.post(
        url,
        {
          images: imagesPath,
        },
        {
          responseType: "blob",
        }
      );

      // const { data } = await useFetch(url, {
      //   key: id,
      //   method: "POST",
      //   body: {
      //     images: imagesPath,
      //   },
      //   headers: {
      //     client_id: useURLs[NODE_ENV].CLIENT_ID,
      //     client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
      //     "Content-Type": "application/json",
      //   },
      //   retry: 2,
      // });

      const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = objectUrl;
      link.setAttribute("download", `${id}_images.zip`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      return;
    }
  };
  return {
    selectedLanguage,
    selectedLocale,
    languageMap,
    setLanguage,
    downloadPdfDoc,
    createContactCrm,
    newsLetter,
    downloadImages,
  };
});
