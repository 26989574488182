import revive_payload_client_4sVQNw7RlN from "/root/var/www/cld-nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/root/var/www/cld-nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/root/var/www/cld-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/root/var/www/cld-nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/root/var/www/cld-nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/root/var/www/cld-nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/root/var/www/cld-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/root/var/www/cld-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import script_YrMPz5ICtR from "/root/var/www/cld-nuxt/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import plugin_tbFNToZNim from "/root/var/www/cld-nuxt/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/root/var/www/cld-nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_8SbxDRbG6Y from "/root/var/www/cld-nuxt/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/root/var/www/cld-nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import useBootstrap_client_ouDJvcVteA from "/root/var/www/cld-nuxt/plugins/useBootstrap.client.ts";
import showToast_client_i3Ts44oG79 from "/root/var/www/cld-nuxt/plugins/showToast.client.js";
import error_handler_kEP5FliEXj from "/root/var/www/cld-nuxt/plugins/error-handler.ts";
import fontawesome_klhsrycjcK from "/root/var/www/cld-nuxt/plugins/fontawesome.js";
import storage_AlMyh9gfRl from "/root/var/www/cld-nuxt/plugins/storage.js";
import vuetify_8NhHJigKc1 from "/root/var/www/cld-nuxt/plugins/vuetify.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  script_YrMPz5ICtR,
  plugin_tbFNToZNim,
  i18n_yfWm7jX06p,
  plugin_8SbxDRbG6Y,
  chunk_reload_client_UciE0i6zes,
  useBootstrap_client_ouDJvcVteA,
  showToast_client_i3Ts44oG79,
  error_handler_kEP5FliEXj,
  fontawesome_klhsrycjcK,
  storage_AlMyh9gfRl,
  vuetify_8NhHJigKc1
]