<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { useAttributeStore } from "./store/AttributeStore";
import { useCategoryStore } from "./store/CategoryStore";
import { useUserStore } from "./store/UserStore";
import { useUtilityStore } from "./store/UtililityStore";
import { useOrderStore } from "./store/OrderStore";

const userStore = useUserStore();
const attributeStore = useAttributeStore();
const utilityStore = useUtilityStore();
const categoryStore = useCategoryStore();
const orderStore = useOrderStore();
const selectedLanguage = computed(() => utilityStore.selectedLanguage);
const languageMap = computed(() => utilityStore.languageMap);

const { locale, setLocale } = useI18n();
const { availableLocales } = useI18n();

// watch(
//   selectedLanguage,

//   (newValue, oldValue) => {
//     attributeStore.getBrandNLicenseFilters();
//   },
//   { deep: true }
// );

onBeforeMount(() => {
  setTimeout(() => {
    userStore.getCldUserData();
    attributeStore.getAttributes();
   
    categoryStore.getCategoriesPromo();

    userStore.getUserData();
  }, 0);

  let languageInStorage = localStorage?.getItem("selectedLanguage");
  if (languageInStorage) {
    const { value } = JSON.parse(languageInStorage)
    languageInStorage = value || "en";
  }
  const userLanguage = (languageInStorage || window?.navigator?.language).split("-")[0];
  if (availableLocales.includes(userLanguage)) {
    // const _lng = languageMap.value[userLanguage];
    utilityStore.setLanguage(userLanguage);
  } else {
    utilityStore.setLanguage("en");
  }

});


</script>
<style scoped>
.container {
  /* max-width: 1600px;
  max-height: auto;
  min-width: 1000px; */
  padding: 0 !important;
}

/* .main {
  width: 100%;
  max-width: max-content;
} */
</style>
