import { default as indexemWARcn2vRMeta } from "/root/var/www/cld-nuxt/pages/index.vue?macro=true";
import { default as cartvngK3L3BLxMeta } from "/root/var/www/cld-nuxt/pages/account/cart.vue?macro=true";
import { default as checkout9EXDXIgTdmMeta } from "/root/var/www/cld-nuxt/pages/account/checkout.vue?macro=true";
import { default as contactwOiaJiYwpJMeta } from "/root/var/www/cld-nuxt/pages/account/contact.vue?macro=true";
import { default as dashboard1HtHmXrp8sMeta } from "/root/var/www/cld-nuxt/pages/account/dashboard.vue?macro=true";
import { default as forget_45passwordRhJ8s9ovMSMeta } from "/root/var/www/cld-nuxt/pages/account/forget-password.vue?macro=true";
import { default as loginnCLZDQ56gmMeta } from "/root/var/www/cld-nuxt/pages/account/login.vue?macro=true";
import { default as _91id_93MiZZaYZSTDMeta } from "/root/var/www/cld-nuxt/pages/account/post-signup/[id].vue?macro=true";
import { default as pre_45signup8Zojo1gxOsMeta } from "/root/var/www/cld-nuxt/pages/account/pre-signup.vue?macro=true";
import { default as profilefYvyIaHw9HMeta } from "/root/var/www/cld-nuxt/pages/account/profile.vue?macro=true";
import { default as contact_45usdc6GqD7EVeMeta } from "/root/var/www/cld-nuxt/pages/contact-us.vue?macro=true";
import { default as backordersVme9RqbgasMeta } from "/root/var/www/cld-nuxt/pages/order/backorders.vue?macro=true";
import { default as cartmanagementZ4V5PDLpIkMeta } from "/root/var/www/cld-nuxt/pages/order/cartmanagement.vue?macro=true";
import { default as deliverablesaYyBpJilSkMeta } from "/root/var/www/cld-nuxt/pages/order/deliverables.vue?macro=true";
import { default as downloadhistory_46clientIYioHspQKtMeta } from "/root/var/www/cld-nuxt/pages/order/downloadhistory.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/root/var/www/cld-nuxt/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as historyELIQKTdb66Meta } from "/root/var/www/cld-nuxt/pages/order/history.vue?macro=true";
import { default as opendocsViPSVn5h55Meta } from "/root/var/www/cld-nuxt/pages/order/opendocs.vue?macro=true";
import { default as searchhistoryD1ERhPQxveMeta } from "/root/var/www/cld-nuxt/pages/order/searchhistory.vue?macro=true";
import { default as unavailableDeliveries3LZudWxNgqMeta } from "/root/var/www/cld-nuxt/pages/order/unavailableDeliveries.vue?macro=true";
import { default as our_45historyHiGf5IXt8NMeta } from "/root/var/www/cld-nuxt/pages/our-history.vue?macro=true";
import { default as our_45servicesfgshxTVZoHMeta } from "/root/var/www/cld-nuxt/pages/our-services.vue?macro=true";
import { default as authDJnwPqZoNOMeta } from "/root/var/www/cld-nuxt/pages/page/auth/auth.js?macro=true";
import { default as pricedown_46clientkcvoVoglT4Meta } from "/root/var/www/cld-nuxt/pages/pricedown.client.vue?macro=true";
import { default as _91name_93NbJYqpPM8HMeta } from "/root/var/www/cld-nuxt/pages/products/[id]/[name].vue?macro=true";
import { default as searchLG3Le6am0uMeta } from "/root/var/www/cld-nuxt/pages/products/search.vue?macro=true";
import { default as profileo33sDojHL3Meta } from "/root/var/www/cld-nuxt/pages/user/profile.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/root/var/www/cld-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "account-cart___fr",
    path: "/fr/account/cart",
    component: () => import("/root/var/www/cld-nuxt/pages/account/cart.vue").then(m => m.default || m)
  },
  {
    name: "account-cart___en",
    path: "/en/account/cart",
    component: () => import("/root/var/www/cld-nuxt/pages/account/cart.vue").then(m => m.default || m)
  },
  {
    name: "account-cart___de",
    path: "/de/account/cart",
    component: () => import("/root/var/www/cld-nuxt/pages/account/cart.vue").then(m => m.default || m)
  },
  {
    name: "account-cart___nl",
    path: "/nl/account/cart",
    component: () => import("/root/var/www/cld-nuxt/pages/account/cart.vue").then(m => m.default || m)
  },
  {
    name: "account-cart___es",
    path: "/es/account/cart",
    component: () => import("/root/var/www/cld-nuxt/pages/account/cart.vue").then(m => m.default || m)
  },
  {
    name: "account-cart___sv",
    path: "/sv/account/cart",
    component: () => import("/root/var/www/cld-nuxt/pages/account/cart.vue").then(m => m.default || m)
  },
  {
    name: "account-checkout___fr",
    path: "/fr/account/checkout",
    component: () => import("/root/var/www/cld-nuxt/pages/account/checkout.vue").then(m => m.default || m)
  },
  {
    name: "account-checkout___en",
    path: "/en/account/checkout",
    component: () => import("/root/var/www/cld-nuxt/pages/account/checkout.vue").then(m => m.default || m)
  },
  {
    name: "account-checkout___de",
    path: "/de/account/checkout",
    component: () => import("/root/var/www/cld-nuxt/pages/account/checkout.vue").then(m => m.default || m)
  },
  {
    name: "account-checkout___nl",
    path: "/nl/account/checkout",
    component: () => import("/root/var/www/cld-nuxt/pages/account/checkout.vue").then(m => m.default || m)
  },
  {
    name: "account-checkout___es",
    path: "/es/account/checkout",
    component: () => import("/root/var/www/cld-nuxt/pages/account/checkout.vue").then(m => m.default || m)
  },
  {
    name: "account-checkout___sv",
    path: "/sv/account/checkout",
    component: () => import("/root/var/www/cld-nuxt/pages/account/checkout.vue").then(m => m.default || m)
  },
  {
    name: "account-contact___fr",
    path: "/fr/account/contact",
    component: () => import("/root/var/www/cld-nuxt/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: "account-contact___en",
    path: "/en/account/contact",
    component: () => import("/root/var/www/cld-nuxt/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: "account-contact___de",
    path: "/de/account/contact",
    component: () => import("/root/var/www/cld-nuxt/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: "account-contact___nl",
    path: "/nl/account/contact",
    component: () => import("/root/var/www/cld-nuxt/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: "account-contact___es",
    path: "/es/account/contact",
    component: () => import("/root/var/www/cld-nuxt/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: "account-contact___sv",
    path: "/sv/account/contact",
    component: () => import("/root/var/www/cld-nuxt/pages/account/contact.vue").then(m => m.default || m)
  },
  {
    name: "account-dashboard___fr",
    path: "/fr/account/dashboard",
    component: () => import("/root/var/www/cld-nuxt/pages/account/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "account-dashboard___en",
    path: "/en/account/dashboard",
    component: () => import("/root/var/www/cld-nuxt/pages/account/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "account-dashboard___de",
    path: "/de/account/dashboard",
    component: () => import("/root/var/www/cld-nuxt/pages/account/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "account-dashboard___nl",
    path: "/nl/account/dashboard",
    component: () => import("/root/var/www/cld-nuxt/pages/account/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "account-dashboard___es",
    path: "/es/account/dashboard",
    component: () => import("/root/var/www/cld-nuxt/pages/account/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "account-dashboard___sv",
    path: "/sv/account/dashboard",
    component: () => import("/root/var/www/cld-nuxt/pages/account/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "account-forget-password___fr",
    path: "/fr/account/forget-password",
    component: () => import("/root/var/www/cld-nuxt/pages/account/forget-password.vue").then(m => m.default || m)
  },
  {
    name: "account-forget-password___en",
    path: "/en/account/forget-password",
    component: () => import("/root/var/www/cld-nuxt/pages/account/forget-password.vue").then(m => m.default || m)
  },
  {
    name: "account-forget-password___de",
    path: "/de/account/forget-password",
    component: () => import("/root/var/www/cld-nuxt/pages/account/forget-password.vue").then(m => m.default || m)
  },
  {
    name: "account-forget-password___nl",
    path: "/nl/account/forget-password",
    component: () => import("/root/var/www/cld-nuxt/pages/account/forget-password.vue").then(m => m.default || m)
  },
  {
    name: "account-forget-password___es",
    path: "/es/account/forget-password",
    component: () => import("/root/var/www/cld-nuxt/pages/account/forget-password.vue").then(m => m.default || m)
  },
  {
    name: "account-forget-password___sv",
    path: "/sv/account/forget-password",
    component: () => import("/root/var/www/cld-nuxt/pages/account/forget-password.vue").then(m => m.default || m)
  },
  {
    name: "account-login___fr",
    path: "/fr/account/login",
    meta: loginnCLZDQ56gmMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/account/login.vue").then(m => m.default || m)
  },
  {
    name: "account-login___en",
    path: "/en/account/login",
    meta: loginnCLZDQ56gmMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/account/login.vue").then(m => m.default || m)
  },
  {
    name: "account-login___de",
    path: "/de/account/login",
    meta: loginnCLZDQ56gmMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/account/login.vue").then(m => m.default || m)
  },
  {
    name: "account-login___nl",
    path: "/nl/account/login",
    meta: loginnCLZDQ56gmMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/account/login.vue").then(m => m.default || m)
  },
  {
    name: "account-login___es",
    path: "/es/account/login",
    meta: loginnCLZDQ56gmMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/account/login.vue").then(m => m.default || m)
  },
  {
    name: "account-login___sv",
    path: "/sv/account/login",
    meta: loginnCLZDQ56gmMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/account/login.vue").then(m => m.default || m)
  },
  {
    name: "account-post-signup-id___fr",
    path: "/fr/account/post-signup/:id()",
    meta: _91id_93MiZZaYZSTDMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/account/post-signup/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-post-signup-id___en",
    path: "/en/account/post-signup/:id()",
    meta: _91id_93MiZZaYZSTDMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/account/post-signup/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-post-signup-id___de",
    path: "/de/account/post-signup/:id()",
    meta: _91id_93MiZZaYZSTDMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/account/post-signup/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-post-signup-id___nl",
    path: "/nl/account/post-signup/:id()",
    meta: _91id_93MiZZaYZSTDMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/account/post-signup/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-post-signup-id___es",
    path: "/es/account/post-signup/:id()",
    meta: _91id_93MiZZaYZSTDMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/account/post-signup/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-post-signup-id___sv",
    path: "/sv/account/post-signup/:id()",
    meta: _91id_93MiZZaYZSTDMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/account/post-signup/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-pre-signup___fr",
    path: "/fr/account/pre-signup",
    meta: pre_45signup8Zojo1gxOsMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/account/pre-signup.vue").then(m => m.default || m)
  },
  {
    name: "account-pre-signup___en",
    path: "/en/account/pre-signup",
    meta: pre_45signup8Zojo1gxOsMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/account/pre-signup.vue").then(m => m.default || m)
  },
  {
    name: "account-pre-signup___de",
    path: "/de/account/pre-signup",
    meta: pre_45signup8Zojo1gxOsMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/account/pre-signup.vue").then(m => m.default || m)
  },
  {
    name: "account-pre-signup___nl",
    path: "/nl/account/pre-signup",
    meta: pre_45signup8Zojo1gxOsMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/account/pre-signup.vue").then(m => m.default || m)
  },
  {
    name: "account-pre-signup___es",
    path: "/es/account/pre-signup",
    meta: pre_45signup8Zojo1gxOsMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/account/pre-signup.vue").then(m => m.default || m)
  },
  {
    name: "account-pre-signup___sv",
    path: "/sv/account/pre-signup",
    meta: pre_45signup8Zojo1gxOsMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/account/pre-signup.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___fr",
    path: "/fr/account/profile",
    component: () => import("/root/var/www/cld-nuxt/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___en",
    path: "/en/account/profile",
    component: () => import("/root/var/www/cld-nuxt/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___de",
    path: "/de/account/profile",
    component: () => import("/root/var/www/cld-nuxt/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___nl",
    path: "/nl/account/profile",
    component: () => import("/root/var/www/cld-nuxt/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___es",
    path: "/es/account/profile",
    component: () => import("/root/var/www/cld-nuxt/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___sv",
    path: "/sv/account/profile",
    component: () => import("/root/var/www/cld-nuxt/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___fr",
    path: "/fr/contact-us",
    component: () => import("/root/var/www/cld-nuxt/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___en",
    path: "/en/contact-us",
    component: () => import("/root/var/www/cld-nuxt/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___de",
    path: "/de/contact-us",
    component: () => import("/root/var/www/cld-nuxt/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___nl",
    path: "/nl/contact-us",
    component: () => import("/root/var/www/cld-nuxt/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___es",
    path: "/es/contact-us",
    component: () => import("/root/var/www/cld-nuxt/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___sv",
    path: "/sv/contact-us",
    component: () => import("/root/var/www/cld-nuxt/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/root/var/www/cld-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/root/var/www/cld-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/root/var/www/cld-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/root/var/www/cld-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/root/var/www/cld-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___sv",
    path: "/sv",
    component: () => import("/root/var/www/cld-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "order-backorders___fr",
    path: "/fr/order/backorders",
    meta: backordersVme9RqbgasMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/backorders.vue").then(m => m.default || m)
  },
  {
    name: "order-backorders___en",
    path: "/en/order/backorders",
    meta: backordersVme9RqbgasMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/backorders.vue").then(m => m.default || m)
  },
  {
    name: "order-backorders___de",
    path: "/de/order/backorders",
    meta: backordersVme9RqbgasMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/backorders.vue").then(m => m.default || m)
  },
  {
    name: "order-backorders___nl",
    path: "/nl/order/backorders",
    meta: backordersVme9RqbgasMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/backorders.vue").then(m => m.default || m)
  },
  {
    name: "order-backorders___es",
    path: "/es/order/backorders",
    meta: backordersVme9RqbgasMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/backorders.vue").then(m => m.default || m)
  },
  {
    name: "order-backorders___sv",
    path: "/sv/order/backorders",
    meta: backordersVme9RqbgasMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/backorders.vue").then(m => m.default || m)
  },
  {
    name: "order-cartmanagement___fr",
    path: "/fr/order/cartmanagement",
    meta: cartmanagementZ4V5PDLpIkMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/cartmanagement.vue").then(m => m.default || m)
  },
  {
    name: "order-cartmanagement___en",
    path: "/en/order/cartmanagement",
    meta: cartmanagementZ4V5PDLpIkMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/cartmanagement.vue").then(m => m.default || m)
  },
  {
    name: "order-cartmanagement___de",
    path: "/de/order/cartmanagement",
    meta: cartmanagementZ4V5PDLpIkMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/cartmanagement.vue").then(m => m.default || m)
  },
  {
    name: "order-cartmanagement___nl",
    path: "/nl/order/cartmanagement",
    meta: cartmanagementZ4V5PDLpIkMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/cartmanagement.vue").then(m => m.default || m)
  },
  {
    name: "order-cartmanagement___es",
    path: "/es/order/cartmanagement",
    meta: cartmanagementZ4V5PDLpIkMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/cartmanagement.vue").then(m => m.default || m)
  },
  {
    name: "order-cartmanagement___sv",
    path: "/sv/order/cartmanagement",
    meta: cartmanagementZ4V5PDLpIkMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/cartmanagement.vue").then(m => m.default || m)
  },
  {
    name: "order-deliverables___fr",
    path: "/fr/order/deliverables",
    meta: deliverablesaYyBpJilSkMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/deliverables.vue").then(m => m.default || m)
  },
  {
    name: "order-deliverables___en",
    path: "/en/order/deliverables",
    meta: deliverablesaYyBpJilSkMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/deliverables.vue").then(m => m.default || m)
  },
  {
    name: "order-deliverables___de",
    path: "/de/order/deliverables",
    meta: deliverablesaYyBpJilSkMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/deliverables.vue").then(m => m.default || m)
  },
  {
    name: "order-deliverables___nl",
    path: "/nl/order/deliverables",
    meta: deliverablesaYyBpJilSkMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/deliverables.vue").then(m => m.default || m)
  },
  {
    name: "order-deliverables___es",
    path: "/es/order/deliverables",
    meta: deliverablesaYyBpJilSkMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/deliverables.vue").then(m => m.default || m)
  },
  {
    name: "order-deliverables___sv",
    path: "/sv/order/deliverables",
    meta: deliverablesaYyBpJilSkMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/deliverables.vue").then(m => m.default || m)
  },
  {
    name: "order-downloadhistory___fr",
    path: "/fr/order/downloadhistory",
    meta: downloadhistory_46clientIYioHspQKtMeta || {},
    component: () => createClientPage(() => import("/root/var/www/cld-nuxt/pages/order/downloadhistory.client.vue").then(m => m.default || m))
  },
  {
    name: "order-downloadhistory___en",
    path: "/en/order/downloadhistory",
    meta: downloadhistory_46clientIYioHspQKtMeta || {},
    component: () => createClientPage(() => import("/root/var/www/cld-nuxt/pages/order/downloadhistory.client.vue").then(m => m.default || m))
  },
  {
    name: "order-downloadhistory___de",
    path: "/de/order/downloadhistory",
    meta: downloadhistory_46clientIYioHspQKtMeta || {},
    component: () => createClientPage(() => import("/root/var/www/cld-nuxt/pages/order/downloadhistory.client.vue").then(m => m.default || m))
  },
  {
    name: "order-downloadhistory___nl",
    path: "/nl/order/downloadhistory",
    meta: downloadhistory_46clientIYioHspQKtMeta || {},
    component: () => createClientPage(() => import("/root/var/www/cld-nuxt/pages/order/downloadhistory.client.vue").then(m => m.default || m))
  },
  {
    name: "order-downloadhistory___es",
    path: "/es/order/downloadhistory",
    meta: downloadhistory_46clientIYioHspQKtMeta || {},
    component: () => createClientPage(() => import("/root/var/www/cld-nuxt/pages/order/downloadhistory.client.vue").then(m => m.default || m))
  },
  {
    name: "order-downloadhistory___sv",
    path: "/sv/order/downloadhistory",
    meta: downloadhistory_46clientIYioHspQKtMeta || {},
    component: () => createClientPage(() => import("/root/var/www/cld-nuxt/pages/order/downloadhistory.client.vue").then(m => m.default || m))
  },
  {
    name: "order-history___fr",
    path: "/fr/order/history",
    meta: historyELIQKTdb66Meta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/history.vue").then(m => m.default || m)
  },
  {
    name: "order-history___en",
    path: "/en/order/history",
    meta: historyELIQKTdb66Meta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/history.vue").then(m => m.default || m)
  },
  {
    name: "order-history___de",
    path: "/de/order/history",
    meta: historyELIQKTdb66Meta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/history.vue").then(m => m.default || m)
  },
  {
    name: "order-history___nl",
    path: "/nl/order/history",
    meta: historyELIQKTdb66Meta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/history.vue").then(m => m.default || m)
  },
  {
    name: "order-history___es",
    path: "/es/order/history",
    meta: historyELIQKTdb66Meta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/history.vue").then(m => m.default || m)
  },
  {
    name: "order-history___sv",
    path: "/sv/order/history",
    meta: historyELIQKTdb66Meta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/history.vue").then(m => m.default || m)
  },
  {
    name: "order-opendocs___fr",
    path: "/fr/order/opendocs",
    meta: opendocsViPSVn5h55Meta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/opendocs.vue").then(m => m.default || m)
  },
  {
    name: "order-opendocs___en",
    path: "/en/order/opendocs",
    meta: opendocsViPSVn5h55Meta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/opendocs.vue").then(m => m.default || m)
  },
  {
    name: "order-opendocs___de",
    path: "/de/order/opendocs",
    meta: opendocsViPSVn5h55Meta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/opendocs.vue").then(m => m.default || m)
  },
  {
    name: "order-opendocs___nl",
    path: "/nl/order/opendocs",
    meta: opendocsViPSVn5h55Meta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/opendocs.vue").then(m => m.default || m)
  },
  {
    name: "order-opendocs___es",
    path: "/es/order/opendocs",
    meta: opendocsViPSVn5h55Meta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/opendocs.vue").then(m => m.default || m)
  },
  {
    name: "order-opendocs___sv",
    path: "/sv/order/opendocs",
    meta: opendocsViPSVn5h55Meta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/opendocs.vue").then(m => m.default || m)
  },
  {
    name: "order-searchhistory___fr",
    path: "/fr/order/searchhistory",
    meta: searchhistoryD1ERhPQxveMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/searchhistory.vue").then(m => m.default || m)
  },
  {
    name: "order-searchhistory___en",
    path: "/en/order/searchhistory",
    meta: searchhistoryD1ERhPQxveMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/searchhistory.vue").then(m => m.default || m)
  },
  {
    name: "order-searchhistory___de",
    path: "/de/order/searchhistory",
    meta: searchhistoryD1ERhPQxveMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/searchhistory.vue").then(m => m.default || m)
  },
  {
    name: "order-searchhistory___nl",
    path: "/nl/order/searchhistory",
    meta: searchhistoryD1ERhPQxveMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/searchhistory.vue").then(m => m.default || m)
  },
  {
    name: "order-searchhistory___es",
    path: "/es/order/searchhistory",
    meta: searchhistoryD1ERhPQxveMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/searchhistory.vue").then(m => m.default || m)
  },
  {
    name: "order-searchhistory___sv",
    path: "/sv/order/searchhistory",
    meta: searchhistoryD1ERhPQxveMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/searchhistory.vue").then(m => m.default || m)
  },
  {
    name: "order-unavailableDeliveries___fr",
    path: "/fr/order/unavailableDeliveries",
    meta: unavailableDeliveries3LZudWxNgqMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/unavailableDeliveries.vue").then(m => m.default || m)
  },
  {
    name: "order-unavailableDeliveries___en",
    path: "/en/order/unavailableDeliveries",
    meta: unavailableDeliveries3LZudWxNgqMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/unavailableDeliveries.vue").then(m => m.default || m)
  },
  {
    name: "order-unavailableDeliveries___de",
    path: "/de/order/unavailableDeliveries",
    meta: unavailableDeliveries3LZudWxNgqMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/unavailableDeliveries.vue").then(m => m.default || m)
  },
  {
    name: "order-unavailableDeliveries___nl",
    path: "/nl/order/unavailableDeliveries",
    meta: unavailableDeliveries3LZudWxNgqMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/unavailableDeliveries.vue").then(m => m.default || m)
  },
  {
    name: "order-unavailableDeliveries___es",
    path: "/es/order/unavailableDeliveries",
    meta: unavailableDeliveries3LZudWxNgqMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/unavailableDeliveries.vue").then(m => m.default || m)
  },
  {
    name: "order-unavailableDeliveries___sv",
    path: "/sv/order/unavailableDeliveries",
    meta: unavailableDeliveries3LZudWxNgqMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/order/unavailableDeliveries.vue").then(m => m.default || m)
  },
  {
    name: "our-history___fr",
    path: "/fr/our-history",
    component: () => import("/root/var/www/cld-nuxt/pages/our-history.vue").then(m => m.default || m)
  },
  {
    name: "our-history___en",
    path: "/en/our-history",
    component: () => import("/root/var/www/cld-nuxt/pages/our-history.vue").then(m => m.default || m)
  },
  {
    name: "our-history___de",
    path: "/de/our-history",
    component: () => import("/root/var/www/cld-nuxt/pages/our-history.vue").then(m => m.default || m)
  },
  {
    name: "our-history___nl",
    path: "/nl/our-history",
    component: () => import("/root/var/www/cld-nuxt/pages/our-history.vue").then(m => m.default || m)
  },
  {
    name: "our-history___es",
    path: "/es/our-history",
    component: () => import("/root/var/www/cld-nuxt/pages/our-history.vue").then(m => m.default || m)
  },
  {
    name: "our-history___sv",
    path: "/sv/our-history",
    component: () => import("/root/var/www/cld-nuxt/pages/our-history.vue").then(m => m.default || m)
  },
  {
    name: "our-services___fr",
    path: "/fr/our-services",
    component: () => import("/root/var/www/cld-nuxt/pages/our-services.vue").then(m => m.default || m)
  },
  {
    name: "our-services___en",
    path: "/en/our-services",
    component: () => import("/root/var/www/cld-nuxt/pages/our-services.vue").then(m => m.default || m)
  },
  {
    name: "our-services___de",
    path: "/de/our-services",
    component: () => import("/root/var/www/cld-nuxt/pages/our-services.vue").then(m => m.default || m)
  },
  {
    name: "our-services___nl",
    path: "/nl/our-services",
    component: () => import("/root/var/www/cld-nuxt/pages/our-services.vue").then(m => m.default || m)
  },
  {
    name: "our-services___es",
    path: "/es/our-services",
    component: () => import("/root/var/www/cld-nuxt/pages/our-services.vue").then(m => m.default || m)
  },
  {
    name: "our-services___sv",
    path: "/sv/our-services",
    component: () => import("/root/var/www/cld-nuxt/pages/our-services.vue").then(m => m.default || m)
  },
  {
    name: "page-auth-auth___fr",
    path: "/fr/page/auth/auth",
    component: () => import("/root/var/www/cld-nuxt/pages/page/auth/auth.js").then(m => m.default || m)
  },
  {
    name: "page-auth-auth___en",
    path: "/en/page/auth/auth",
    component: () => import("/root/var/www/cld-nuxt/pages/page/auth/auth.js").then(m => m.default || m)
  },
  {
    name: "page-auth-auth___de",
    path: "/de/page/auth/auth",
    component: () => import("/root/var/www/cld-nuxt/pages/page/auth/auth.js").then(m => m.default || m)
  },
  {
    name: "page-auth-auth___nl",
    path: "/nl/page/auth/auth",
    component: () => import("/root/var/www/cld-nuxt/pages/page/auth/auth.js").then(m => m.default || m)
  },
  {
    name: "page-auth-auth___es",
    path: "/es/page/auth/auth",
    component: () => import("/root/var/www/cld-nuxt/pages/page/auth/auth.js").then(m => m.default || m)
  },
  {
    name: "page-auth-auth___sv",
    path: "/sv/page/auth/auth",
    component: () => import("/root/var/www/cld-nuxt/pages/page/auth/auth.js").then(m => m.default || m)
  },
  {
    name: "pricedown___fr",
    path: "/fr/pricedown",
    meta: pricedown_46clientkcvoVoglT4Meta || {},
    component: () => createClientPage(() => import("/root/var/www/cld-nuxt/pages/pricedown.client.vue").then(m => m.default || m))
  },
  {
    name: "pricedown___en",
    path: "/en/pricedown",
    meta: pricedown_46clientkcvoVoglT4Meta || {},
    component: () => createClientPage(() => import("/root/var/www/cld-nuxt/pages/pricedown.client.vue").then(m => m.default || m))
  },
  {
    name: "pricedown___de",
    path: "/de/pricedown",
    meta: pricedown_46clientkcvoVoglT4Meta || {},
    component: () => createClientPage(() => import("/root/var/www/cld-nuxt/pages/pricedown.client.vue").then(m => m.default || m))
  },
  {
    name: "pricedown___nl",
    path: "/nl/pricedown",
    meta: pricedown_46clientkcvoVoglT4Meta || {},
    component: () => createClientPage(() => import("/root/var/www/cld-nuxt/pages/pricedown.client.vue").then(m => m.default || m))
  },
  {
    name: "pricedown___es",
    path: "/es/pricedown",
    meta: pricedown_46clientkcvoVoglT4Meta || {},
    component: () => createClientPage(() => import("/root/var/www/cld-nuxt/pages/pricedown.client.vue").then(m => m.default || m))
  },
  {
    name: "pricedown___sv",
    path: "/sv/pricedown",
    meta: pricedown_46clientkcvoVoglT4Meta || {},
    component: () => createClientPage(() => import("/root/var/www/cld-nuxt/pages/pricedown.client.vue").then(m => m.default || m))
  },
  {
    name: "products-id-name___fr",
    path: "/fr/products/:id()/:name()",
    component: () => import("/root/var/www/cld-nuxt/pages/products/[id]/[name].vue").then(m => m.default || m)
  },
  {
    name: "products-id-name___en",
    path: "/en/products/:id()/:name()",
    component: () => import("/root/var/www/cld-nuxt/pages/products/[id]/[name].vue").then(m => m.default || m)
  },
  {
    name: "products-id-name___de",
    path: "/de/products/:id()/:name()",
    component: () => import("/root/var/www/cld-nuxt/pages/products/[id]/[name].vue").then(m => m.default || m)
  },
  {
    name: "products-id-name___nl",
    path: "/nl/products/:id()/:name()",
    component: () => import("/root/var/www/cld-nuxt/pages/products/[id]/[name].vue").then(m => m.default || m)
  },
  {
    name: "products-id-name___es",
    path: "/es/products/:id()/:name()",
    component: () => import("/root/var/www/cld-nuxt/pages/products/[id]/[name].vue").then(m => m.default || m)
  },
  {
    name: "products-id-name___sv",
    path: "/sv/products/:id()/:name()",
    component: () => import("/root/var/www/cld-nuxt/pages/products/[id]/[name].vue").then(m => m.default || m)
  },
  {
    name: "products-search___fr",
    path: "/fr/products/search",
    meta: searchLG3Le6am0uMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/products/search.vue").then(m => m.default || m)
  },
  {
    name: "products-search___en",
    path: "/en/products/search",
    meta: searchLG3Le6am0uMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/products/search.vue").then(m => m.default || m)
  },
  {
    name: "products-search___de",
    path: "/de/products/search",
    meta: searchLG3Le6am0uMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/products/search.vue").then(m => m.default || m)
  },
  {
    name: "products-search___nl",
    path: "/nl/products/search",
    meta: searchLG3Le6am0uMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/products/search.vue").then(m => m.default || m)
  },
  {
    name: "products-search___es",
    path: "/es/products/search",
    meta: searchLG3Le6am0uMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/products/search.vue").then(m => m.default || m)
  },
  {
    name: "products-search___sv",
    path: "/sv/products/search",
    meta: searchLG3Le6am0uMeta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/products/search.vue").then(m => m.default || m)
  },
  {
    name: "user-profile___fr",
    path: "/fr/user/profile",
    meta: profileo33sDojHL3Meta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: "user-profile___en",
    path: "/en/user/profile",
    meta: profileo33sDojHL3Meta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: "user-profile___de",
    path: "/de/user/profile",
    meta: profileo33sDojHL3Meta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: "user-profile___nl",
    path: "/nl/user/profile",
    meta: profileo33sDojHL3Meta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: "user-profile___es",
    path: "/es/user/profile",
    meta: profileo33sDojHL3Meta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: "user-profile___sv",
    path: "/sv/user/profile",
    meta: profileo33sDojHL3Meta || {},
    component: () => import("/root/var/www/cld-nuxt/pages/user/profile.vue").then(m => m.default || m)
  }
]