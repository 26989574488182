import { NODE_ENV } from "../../constants/env";
import { useURLs } from "../../constants/api.urls";

export const getAttributeFiltersLabels = async () => {
  try {
    // const myHeaders = new Headers();
    // myHeaders.append("client_id", useURLs[NODE_ENV].CLIENT_ID);
    // myHeaders.append("client_secret", useURLs[NODE_ENV].CLIENT_SECERET);

    // const requestOptions = {
    //   method: "GET",
    //   headers: myHeaders,
    // };
    // const url = useURLs[NODE_ENV].FILTERS_LABELS_URL;
    // const res = await fetch(url, requestOptions);
    // const data = await res.json();
    // return data;

    const { data } = await useFetch(url, {
      key: prefix,
      method: "GET",
      headers: {
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
        "Content-Type": "application/json",
      },
      retry: 1,
    });
    return data;
  } catch (error) {
    return [];
  }
};

export const findAttributes = async (id) => {
  if (!id) {
    return;
  }
  // const myHeaders = new Headers();
  // myHeaders.append("client_id", useURLs[NODE_ENV].CLIENT_ID);
  // myHeaders.append("client_secret", useURLs[NODE_ENV].CLIENT_SECERET);
  // myHeaders.append("Content-Type", "application/json");

  // const requestOptions = {
  //   method: "GET",
  //   headers: myHeaders,
  // };
  // const res = await fetch(`${url}/${id}`, requestOptions);
  // const data = await res.json();

  const url = useURLs[NODE_ENV].ATTRIBUTE_FIND_URL;
  const { data } = await useFetch(`${url}/${id}`, {
    key: id,
    method: "GET",
    headers: {
      client_id: useURLs[NODE_ENV].CLIENT_ID,
      client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
      "Content-Type": "application/json",
    },
    retry: 1,
  });
  return data;
};
