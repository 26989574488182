import { getRedis, setRedis } from "../api/redis";
import { findAttributes } from "../api/attributes";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useURLs } from "~~/constants/api.urls";
import { NODE_ENV } from "~~/constants/env";
import { useUtilityStore } from "./UtililityStore";

export const useAttributeStore = defineStore("attribute", () => {
  const uitilityStore = useUtilityStore();
  const attributes = ref({});
  const tagsOptionLables = ref({});
  const attributeOptionLables = ref({});
  const selectedFilters = ref({});
  const attributeTags = ref([]);
  const lables = ref([]);
  const attributesMain = ref({});
  const attributesDetails = ref({});

  const brandAttributes = ref({});
  const licenseAttributes = ref({});
  const selectedLanguage = computed(() => uitilityStore.selectedLanguage);
  const findAttributesById = async (id) => {
    /* const fromCache = await getRedis(`attribute:${id}`);
    if (fromCache) {
      attributes.value = fromCache;
    }
    const fromCacheAttributes = await getRedis(`attributes:${id}`);
    if (fromCacheAttributes) {
      attributesMain.value = fromCacheAttributes.attributesMain;
      attributesDetails.value = fromCacheAttributes.attributesDetails;
    }*/
    const data = await findAttributes(id);

    if (data.value?.["attributes"]) {
      // const sortedObject = Object.fromEntries(
      //   Object.entries(response).sort((a, b) => a[0].localeCompare(b[0]))
      // );

      const flatAttributes = Object.values(data.value["attributes"]).flat();

      attributesMain.value = flatAttributes.filter(
        (x) => x.enable_main && x.data !== false && x.data !== null
      );
      let _attributesMain = {};
      let _attributesDetails = {};
      for (const att of flatAttributes) {
        if (att.enable_main && att.data !== false && att.data !== null) {
          if (
            _attributesMain[att.groupLables.fr_BE] &&
            _attributesMain[att.groupLables.fr_BE].length
          ) {
            _attributesMain[att.groupLables.fr_BE].push(att);
          } else {
            _attributesMain[att.groupLables.fr_BE] = [att];
          }
        }

        if (att.enable_details) {
          if (
            _attributesDetails[att.groupLables.fr_BE] &&
            _attributesDetails[att.groupLables.fr_BE].length
          ) {
            _attributesDetails[att.groupLables.fr_BE].push(att);
          } else {
            _attributesDetails[att.groupLables.fr_BE] = [att];
          }
        }
      }

      attributesDetails.value = _attributesDetails;
      attributesMain.value = _attributesMain;
      // attributesDetails.value = flatAttributes
      //   .filter(
      //     (x) => x.enable_details && (x.data !== false || x.data !== null)
      //   )
      //   .sort((a, b) => a.type.localeCompare(b.type));

      console.log("attributesDetails", attributesDetails.value);
      attributes.value = data.value["attributes"];
      /*
      await setRedis(`attributes:${id}`, {
        attributesDetails: attributesDetails.value,
        attributesMain: attributesMain.value,
      });
      await setRedis(`attribute:${id}`, data.value["attributes"]); */
    } else {
      console.log(`error fetching attribute for id:${id}`);
    }
  };
  const getAttributes = async () => {
    await getAttributesHttp();
    getAttFiltersLabels();
  };
  const getAttributesHttp = async () => {
    const url = useURLs[NODE_ENV].FILTERS_LABELS_URL;
    const { data } = await useFetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
      },
    });

    if (data.value) {
      lables.value = data.value;
    } else {
      console.log("error fetching attributes");
    }
  };

  const getAttFiltersLabels = async () => {
    let lablesDictionary = {};
    let _tagsOptionLables = {};
    for (let i = 0; i < lables.value.length; i++) {
      const _lable = lables.value[i]["options"].map((x) => ({
        [x.Code]: x.Labels,
      }));
      lablesDictionary = {
        ...lablesDictionary,
        [lables.value[i]["id"]]: {
          ...lables.value[i]["lables"],
          lables: {
            ...Object.assign({}, ..._lable),
          },
        },
      };
      _tagsOptionLables = {
        ..._tagsOptionLables,
        ...Object.assign({}, ..._lable),
      };
    }
    // ADDING CUSTOM LABLES
    lablesDictionary["in_stock"] = {
      de_DE: "Auf Lager",
      en_GB: "In stock",
      es_ES: "En stock",
      fr_BE: "En stock",
      nl_BE: "Op voorraad",
      lables: {},
    };
    lablesDictionary["in_reserved"] = {
      de_DE: "Reserviert",
      en_GB: "Reserved",
      es_ES: "Reservado",
      fr_BE: "Réservé",
      nl_BE: "Gereserveerd",
      sv_SE: "Reserverad",
      lables: {},
    };
    lablesDictionary["dvd_suggested_price"] = {
      de_DE: "Preis",
      en_GB: "Price",
      es_ES: "Precio",
      fr_BE: "Prix",
      nl_BE: "Prijs",
      lables: {},
    };
    attributeOptionLables.value = lablesDictionary;
    tagsOptionLables.value = _tagsOptionLables;
  };
  const getSelectedAttributesTags = () => {
    let att = Object.values(selectedFilters.value.attribute);
    if (att && att.length) {
      let attTags = [];
      const gg = Object.entries(att).flatMap((x) => x[1]);
      if (gg && gg.length) {
        for (const k in gg) {
          if (gg[k]) {
            Object.keys(gg[k]).forEach((x) => {
              if (gg[k][x]) {
                attTags.push(x);
              }
            });
          }
        }
      }
      attributeTags.value = attTags;
    }
  };
  const getBrandNLicenseFiltersHttp = async ({
    sortOrder,
    filterInput,
    range,
    startRowIndex,
    selectedFilters,
    selectedLanguage,
    priceMin,
    priceMax,
    searchByMultiIds,
  }) => {
    const payload = JSON.stringify({
      prefix: filterInput,
      range: range,
      startRowIndex: startRowIndex,
      filters: selectedFilters,
      selectedLanguage,
      sortOrder: sortOrder,
      searchByMultiIds,
      ...{ ...((priceMin > 1 || priceMax < 9999) && { priceMin, priceMax }) },
    });
    debugger
    const url = useURLs[NODE_ENV].BRAND_N_LICENSE_AGG;
    const { data } = await useFetch(url, {
      key: payload,
      method: "POST",
      headers: {
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
        "Content-Type": "application/json",
      },
      body: payload,
      retry: 1,
    });
    debugger
    return data;
  };
  const getBrandNLicenseFilters = async (reqPayload) => {
    // getRedis("brand_license_attributes").then((fromCache) => {
    //   if (fromCache) {
    //     brandAttributes.value = fromCache.brand;
    //     licenseAttributes.value = fromCache.license;
    //   }
    // });

  const payload = JSON.stringify(reqPayload);
  debugger
  const url = useURLs[NODE_ENV].BRAND_N_LICENSE_AGG;
  const { data } = await useFetch(url, {
    key: payload,
    method: "POST",
    headers: {
      client_id: useURLs[NODE_ENV].CLIENT_ID,
      client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
      "Content-Type": "application/json",
    },
    body: payload,
    retry: 1,
  });
  brandAttributes.value = data.value.brand;
  licenseAttributes.value = data.value.license;
  


    // setRedis({
    //   key: "brand_license_attributes",
    //   value: {
    //     brand: data.value.brand,
    //     license: data.value.license,
    //   },
    // }).then();
  };

  return {
    attributes,
    findAttributesById,
    getAttributes,
    getAttFiltersLabels,
    getSelectedAttributesTags,
    getBrandNLicenseFilters,
    brandAttributes,
    licenseAttributes,
    attributeOptionLables,
    tagsOptionLables,
    attributesMain,
    attributesDetails,
  };
});
